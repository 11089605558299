<template>
  <div class="wrap__contents agent-referrals">
    <div class="toolbar">
      <div class="toolbar__items buttons">
        <button
          type="button"
          class="btn--refresh"
          @click="search"
        >
          <i class="ico--refresh"></i>
        </button>

        <button
          class="btn--create"
          title="create"
          @click="handleClickShowModal"
        >
          <i class="ico--create"></i>

          <span>{{ translate(translations, 'create agents') }}</span>
        </button>
      </div>
    </div>

    <div class="wrap__inner">
      <div class="tree">
        <agent-tree-node
          v-for="(currReferral, referralKey) in referralList"
          :key="referralKey"
          :data="currReferral"
          :headers="headers"
        >
          <template #[`value.login_id`]="{item}">
            <span
              style="cursor:pointer"
              @click="openAgentPopup(item.id, 'users/confirmed')"
            >
              {{ item.login_id }}
            </span>
          </template>

          <template #[`value.login_name`]="{item}">
            <span
              style="cursor:pointer"
              @click="openAgentPopup(item.id, 'users/confirmed')"
            >
              {{ item.login_name }}
            </span>
          </template>

          <template #[`value.codes`]="{item}">
            {{ item.codes }}
          </template>

          <template #[`value.total_balance`]="{item}">
            {{ item.user_stats.total_balance | FormatAmount('whole') }}
          </template>

          <template #[`value.total_points`]="{item}">
            {{ item.user_stats.total_points | FormatAmount('whole') }}
          </template>

          <template #[`value.casino_lost_commission`]="{item}">
            {{ _get(item, 'config.commission.casino.lost', 0) | FormatAmount('whole') }}
          </template>

          <template #[`value.total_cash_in`]="{item}">
            {{ item.total_cash_in | FormatAmount('whole') }}
          </template>

          <template #[`value.total_cash_out`]="{item}">
            {{ item.total_cash_out | FormatAmount('whole') }}
          </template>

          <template #[`value.total_cash_net`]="{item}">
            {{ item.total_cash_net | FormatAmount('whole') }}
          </template>

          <template #[`value.forwarding_commission_personal`]="{item}">
            {{ item.forwarding_commission_personal | FormatAmount('whole') }}
          </template>

          <template #[`value.forwarding_commission_referral`]="{item}">
            {{ item.forwarding_commission_referral | FormatAmount('whole') }}
          </template>

          <template #[`value.forwarding_commission`]="{item}">
            {{ item.forwarding_commission | FormatAmount('whole') }}
          </template>

          <template #[`value.total_commission`]="{item}">
            {{ item.total_commission | FormatAmount('whole') }}
          </template>

          <template #[`value.total_withdraw`]="{item}">
            {{ item.total_withdraw | FormatAmount('whole') }}
          </template>
        </agent-tree-node>
      </div>
    </div>

    <modal
      v-if="modals.createAgents.status"
      :title="translate(translations, 'create agents')"
      :activate="modals.createAgents.status"
      class="modal--create-agents"
      @activate="modals.createAgents.status = $event"
    >
      <template slot="body">
        <div class="form">
          <!-- <div class="form__head">
            <div class="form__head-item">
              <div class="form__item">
                <div class="form__label">
                  {{ translate(translations, 'agent count') }}
                </div>

                <div class="form__data">
                  <div class="input-wrap">
                    <input
                      v-model.number="modals.createAgents.data.count"
                      v-focus
                      type="text"
                      @input="handleCreateAgentCountInput"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div
            v-for="(form, formKey) in modals.createAgents.data.forms"
            :key="formKey"
            class="form__body"
          >
            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'agent id') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model="form.login_id"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'agent name') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model="form.login_name"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'password') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model="form.password"
                    type="password"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'phone') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model="form.phone"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'casino lost commission') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model.number="form.config.commission.casino.lost"
                    type="number"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank type') }}
              </div>

              <div class="form__data">
                <select2
                  v-model="form.bank_type"
                  :with-reset="true"
                  @change.native="handleBankTypeChange(form)"
                >
                  <option2
                    v-for="type in bankTypes"
                    :key="type.id"
                    :value="type.id"
                    :selected="form.bank_type === type.id"
                  >
                    {{ translate(translations, type.name) }}
                  </option2>
                </select2>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank name') }}
              </div>

              <div class="form__data">
                <select2
                  v-model="form.bank_id"
                  :with-reset="true"
                >
                  <option2
                    v-for="bank in filteredBanks(form.bank_type)"
                    :key="bank.id"
                    :value="bank.id"
                    :selected="form.bank_id == bank.id"
                  >
                    {{ bank.name }}
                  </option2>
                </select2>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank account') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model="form.bank_account_name"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank number') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model.number="form.bank_number"
                    type="number"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank password') }}
              </div>

              <div class="form__data">
                <div class="input-wrap">
                  <input
                    v-model.number="form.bank_password"
                    type="number"
                  >
                </div>
              </div>
            </div>

            <!-- <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'currency') }}
              </div>

              <div class="form__data">
                <select2
                  v-model="form.currency_id"
                  :with-reset="true"
                >
                  <option2
                    v-for="(currency, currencyKey) in currencies"
                    :key="currencyKey"
                    :selected="form.currency_id == currency.value"
                    :value="currency.value"
                  >
                    {{ currency.text }}
                  </option2>
                </select2>
              </div>
            </div> -->

            <div
              class="form__item"
              style="height: 100%"
            >
              <div class="form__label">
                {{ translate(translations, 'memo') }}
              </div>

              <div class="form__data">
                <div class="textarea-wrap">
                  <textarea v-model="form.memo">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          class="btn--confirm"
          @click="handleClickCreateAgents"
        >
          <i class="ico--create"></i>
          <span>
            {{ translate(translations, 'create') }}
          </span>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

// components
import AgentTreeNode from './AgentTreeNode'
import Modal from '@/components/base/Modal'

// libs
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import focus from '@/assets/js/directives/Focus'

// mixins
import siteDefaults from '@/assets/js/mixins/base/SiteDefaults'

//= translation
import translations from '@/assets/js/translations/agent-referrals'

// filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

export default {
  name: 'AgentReferrals',

  components: {
    AgentTreeNode,
    Modal
  },

  directives: {
    focus
  },

  filters: {
    FormatAmount
  },

  mixins: [
    siteDefaults
  ],

  data () {
    return {
      translations,

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      modals: {
        createAgents: {
          status: 0,
          data: {
            count: 1,
            forms: []
          },
          reset () {
            this.status = false
            this.data = {
              count: 1,
              forms: []
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapState('bank', [
      'banks',
      'bankTypes'
    ]),

    ...mapState('vip', [
      'vipList'
    ]),

    ...mapState('level', [
      'levels'
    ]),

    ...mapState('agent', {
      lastCommissionUpdate: 'lastCommissionUpdate',
      referralList: 'referralList'
    }),

    headers () {
      const headers = [
        {
          text: 'login id',
          value: 'login_id'
        },
        {
          text: 'login name',
          value: 'login_name'
        },
        {
          text: 'code',
          value: 'codes'
        },
        {
          text: 'total balance',
          value: 'total_balance'
        },
        {
          text: 'total points',
          value: 'total_points'
        },
        {
          text: 'casino lost commission',
          value: 'casino_lost_commission'
        },
        {
          text: 'total_cash_in',
          value: 'total_cash_in'
        },
        {
          text: 'total_cash_out',
          value: 'total_cash_out'
        },
        {
          text: 'total_cash_net',
          value: 'total_cash_net'
        },
        {
          text: 'total_commission_personal',
          value: 'forwarding_commission_personal'
        },
        {
          text: 'total_commission_referral',
          value: 'forwarding_commission_referral'
        },
        {
          text: 'forwarding_commission',
          value: 'forwarding_commission'
        },
        {
          text: 'total_withdraw',
          value: 'total_withdraw'
        },
        {
          text: 'total_commission',
          value: 'total_commission'
        }
      ]

      headers.forEach(curr => {
        curr.text = this.translate(translations, curr.text)
      })

      return headers
    }
  },

  watch: {
    '$route' ($router) {
      this.search($router.query)
    }
  },

  async created () {
    await this.search()
    await this.getBanks({ status: 'active' })
    await this.getVips()
    await this.getLevels()
  },

  methods: {
    ...{ _get },

    ...mapActions({
      createAgents: 'agent/create',
      getAgentReferrals: 'agent/getAgentReferrals',

      getBanks: 'bank/get',

      getVips: 'vip/get',

      getLevels: 'level/get'
    }),

    filteredBanks (bankTypeId) {
      return bankTypeId
        ? this.banks.filter(e => e.type_id == bankTypeId)
        : this.banks
    },

    handleClickShowModal () {
      this.handleCreateAgentCountInput()
      this.modals.createAgents.status = true
    },

    handleCreateAgentCountInput () {
      const modal = this.modals.createAgents

      const defaultBank = this.banks[0]
      const defaultBankId = _get(defaultBank, 'id')
      const defaultBankType = _get(defaultBank, 'type_id')

      const form = {
        referral_id: this.$route.params.id,
        login_id: '',
        login_name: '',
        password: '',
        phone: '',
        bank_type: defaultBankType,
        bank_id: defaultBankId,
        bank_account_name: '',
        bank_number: '',
        bank_password: '',
        currency_id: 1,
        config: { commission: { casino: { lost: 0 } } },
        memo: ''
      }

      modal.data.forms = Array.from({ length: modal.data.count || 1 }, (_, i) => {
        return modal.data.forms[i] || JSON.parse(JSON.stringify(form))
      })
    },

    handleClickCreateAgents () {
      const translate = str => this.translate(translations, str)
      const modal = this.modals.createAgents

      const success = async () => {
        try {
          const find = modal.data.forms.find(e => isNaN(e.bank_password) || isNaN(e.bank_number))

          if (find) {
            return this.$showAlert({
              type: 'error',
              text: translate('Bank password and bank Number should be a number')
            })
          }

          this.showDataLoading = true

          await this.createAgents(modal.data.forms)

          this.$showAlert({
            type: 'success',
            text: 'success create'
          })

          this.showCreate = false
          this.search()
          modal.reset()
        } catch (error) {
          const errorName = _get(error, 'response.data.name')
          const errorMessage = _get(error, 'response.data.msg')

          if (errorName === 'VALIDATION_ERROR') {
            console.log(errorMessage)
            return this.$showAlert({
              type: 'error',
              text: translate('validation error')
            })
          }

          if (errorName === 'AGENT_ERROR') {
            if (errorMessage === 'LOGIN_ID_EXISTS') {
              return this.$showAlert({
                type: 'error',
                text: translate('error user exist')
              })
            }

            if (errorMessage === 'MAX_REFERRAL') {
              return this.$showAlert({
                type: 'error',
                text: translate('max referral')
              })
            }

            if (errorMessage === 'CHILD_COMMISSION_GREATER_THAN_PARENT') {
              return this.$showAlert({
                type: 'error',
                text: translate('child commission greater than parent')
              })
            }
          }

          this.$showAlert({ type: 'error' })
        } finally {
          this.showDataLoading = false
        }
      }

      return this.$showAlert({
        type: 'prompt',
        title: 'create',
        success
      })
    },

    handleBankTypeChange (form) {
      const filteredBanks = this.filteredBanks(form.bank_type)

      form.bank_id = _get(filteredBanks, [0, 'id'])
    },

    async search (query) {
      try {
        // == flags reset
        this.isEmpty = false
        this.isDataLoading = !this.showDataLoading

        await this.getAgentReferrals({
          agent_id: this.$route.params.id,
          ...this.$route.query
        })

        // == flags set
        this.isDataLoading = false
        this.showDataLoading = false
        this.isEmpty = _isEmpty(this.referralList)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
