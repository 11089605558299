<template>
  <div>
    <div
      class="tree__item"
      :class="`tree__item--depth-${data.depth}`"
    >
      <div class="item__header">
        <div class="header__body">
          <div
            v-for="(currHeader, headerKey) in headers"
            :key="headerKey"
            class="header__item"
          >
            <div class="item__key">
              <slot
                :name="`key.${currHeader.value}`"
                :item="data"
                :index="headerKey"
              >
                {{ currHeader.text }}
              </slot>
            </div>

            <div class="item__value">
              <slot
                :name="`value.${currHeader.value}`"
                :item="data"
                :index="headerKey"
              >
                {{ data[currHeader.value] }}
              </slot>
            </div>
          </div>
        </div>

        <div
          v-if="data.children.length"
          class="header__toggle"
          @click="state.showChildren = !state.showChildren"
        >
          <div
            class="btn__toggle"
            :class="{
              'btn__toggle--left': !state.showChildren,
              'btn__toggle--down': state.showChildren,
            }"
          ></div>
        </div>
      </div>
    </div>

    <!-- v-if="data.children.length" -->
    <div v-show="state.showChildren && data.children.length">
      <agent-tree-node
        v-for="(currData, dataKey) in data.children"
        :key="dataKey"
        :headers="headers"
        :data="currData"
      >
        <template
          v-for="slot in Object.keys($scopedSlots)"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          ></slot>
        </template>
      </agent-tree-node>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentTreeNode',

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    state: {
      showChildren: true
    }
  })
}
</script>

<style lang="scss" scoped>
.tree__item {
  position: relative;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  font-weight: 500;

  $depths: (0, 1, 2, 3, 4, 5);
  $depth-indention: 30;

  @each $depth in $depths {
    $background-base: #7f91a8;

    &.tree__item--depth-#{$depth} {
      margin-left: calc(#{$depth}px * #{$depth-indention}) !important;
      width: calc(100% - calc(#{$depth}px * #{$depth-indention}));
      background-color: #202631;
      border: 1px solid #4d5463;
    }
  }

  .item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__body {
      display: flex;
      width: 100%;
      min-width: 500px;

      .header__item {
        user-select: none;

        &:not(:last-child) {
          margin-right: 20px;
          padding-right: 20px;
          border-right: 1px solid rgba(71, 71, 71, 0.5);
        }

        .item__key {
          color: rgb(221, 221, 221);
          font-size: 0.85em;
        }

        .item__value {
          margin-top: 5px;
          font-weight: bold;
          text-align: center;
        }
      }
    }

    .header__toggle {
      width: 30px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      .btn__toggle {
        width: 10px;
        height: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        border-left: 2px solid rgba(0, 0, 0, 0.3);
        transition: transform 0.2s ease;

        &.btn__toggle--left {
          transform: rotate(45deg);
        }

        &.btn__toggle--down {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>
