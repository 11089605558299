export default {
  login_id: '아이디',
  login_name: '닉네임',
  code: '코드',
  total_balance: '보유 금액',
  total_points: '포인트',
  total_bet: '배팅금',
  total_win: '당첨금',
  total_net: '배팅 손익',
  total_cash_in: '입금',
  total_cash_out: '출금',
  total_cash_net: ' 입출 손익',

  casino_lost_commission: '커미션',

  total_commission_share: '커미션',

  total_commission_personal: '유저 커미션',
  total_commission_referral: '하부 커미션',
  total_commission: '총 커미션',

  created_at: '생성일자',

  create_agents: '매장 생성',
  agent_id: '매장 ID',
  agent_name: '매장 이름',
  password: '비밀번호',
  phone: '연락처',
  bank_type: '은행 종류',
  bank_name: '은행명',
  bank_account: '예금주',
  bank_number: '계좌번호',
  bank_password: '출금비밀번호',
  memo: '메모',
  create: '생성확인',
  child_commission_greater_than_parent: '매장 수수료를 초과할 수 없습니다',
  forwarding_commission: '총 커미션',
  total_withdraw: '총 출금'
}
